import { isFeatureEnabled, fetchJson, reportErrorAndShowMessage, localizationKey } from "js/includes/common/utils"

export const requestQuickBooksIntegrationSettings = () => async dispatch => {
  if (!isFeatureEnabled("ninja_psa")) return

  try {
    const response = await fetchJson("/psa/integration")
    const integrationData = response.find(({ typeId }) => typeId === "QUICK_BOOKS") || {}

    dispatch({
      type: "NINJA_PSA_SET_QUICK_BOOKS_CONFIGURATION",
      payload: {
        ...integrationData,
        enabled: integrationData.status === "ACTIVE",
      },
    })
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error while fetching QuickBooks settings"))
  }
}
