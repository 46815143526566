import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSms } from "@fortawesome/pro-solid-svg-icons"
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons"
import { MicrosoftTeamsIcon } from "@ninjaone/icons"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { WEBHOOK_LOGO } from "js/includes/common/utils"

const channelIcons = {
  SLACK: <img src="/img/vendors/slack_logo.png" alt="slack_logo" />,
  PAGER_DUTY: <img src="/img/vendors/pager_duty_logo.png" alt="pager_duty_logo" />,
  WEBHOOK: <img src={WEBHOOK_LOGO} alt="webhook_logo" />,
  EMAIL: <FontAwesomeIcon icon={faEnvelope} size="3x" />,
  SMS: <FontAwesomeIcon icon={faSms} size="3x" />,
  MICROSOFT_TEAMS: <MicrosoftTeamsIcon />,
}

export const channelTypeNames = {
  SLACK: localizationKey("Slack"),
  MICROSOFT_TEAMS: localizationKey("Microsoft Teams"),
  PAGER_DUTY: localizationKey("PagerDuty"),
  WEBHOOK: localizationKey("Webhook"),
  EMAIL: localizationKey("Email"),
  SMS: localizationKey("SMS"),
}

export const getChannelIcon = type => channelIcons[type] ?? null

export const getChannelTypeName = type => (channelTypeNames[type] ? localized(channelTypeNames[type]) : null)

const errorTokens = {
  url_supplied_is_invalid: localizationKey("Invalid URL"),
  communication_error_with_slack: localizationKey("There's a communication error with Slack"),
  invalid_slack_response: localizationKey("Response from Slack was invalid"),
  null_response_from_slack: localizationKey("Response from Slack was null"),
  not_able_to_parse_response_from_slack: localizationKey("Not able to parse response body from Slack"),
  request_to_slack_returned_error: localizationKey("Request to Slack did not return a 200 status code"),
  unknown_error_occurred: localizationKey("Unknown error occurred"),
}

export const getErrorToken = errorCode => errorTokens[errorCode] ?? localizationKey("Error")
