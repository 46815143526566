import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Button, Dropdown, Text } from "@ninjaone/components"
import { OpenArrowIcon } from "@ninjaone/icons"
import { getTextSize, sizer } from "@ninjaone/utils"
import { Box, Flex } from "js/includes/components/Styled"
import { localizationKey, localized, isNotNil } from "js/includes/common/utils"

export const StyledBox = styled(Box)`
  background-color: ${({ theme }) => theme.colorBackground};
  border: ${({ theme }) => `1px solid ${theme.colorBorderWeakest}`};
  border-radius: ${sizer(1)};
  color: ${({ theme }) => theme.colorTextStrong};
`

const StyledLinkContainer = styled(Box)`
  padding: ${sizer(3)};
  font-weight: 500;
  font-size: ${getTextSize("sm")};
`

const StyledDataTitle = styled(Flex)`
  flex-direction: column;
  min-width: ${sizer(38)};
  color: ${({ theme }) => theme.color.black["050"]};
`

export const DataPanels = ({ leftPanel, rightPanel, gap }) => (
  <Flex>
    <Flex flexDirection="column" {...{ gap }}>
      {leftPanel}
    </Flex>
    <Flex flexDirection="column" {...{ gap }}>
      {rightPanel}
    </Flex>
  </Flex>
)

export const DataTitle = ({ token, text, children }) => (
  <StyledDataTitle>
    {isNotNil(token) || isNotNil(text) ? (
      <Text size="sm" {...{ token }}>
        {text}
      </Text>
    ) : (
      children
    )}
  </StyledDataTitle>
)

export const DataDescription = ({ token, text, children }) => (
  <Flex flex={1} marginLeft={sizer(8)} flexDirection="column">
    {isNotNil(token) || isNotNil(text) ? (
      <Text textWrap size="sm" {...{ token }}>
        {text}
      </Text>
    ) : (
      children
    )}
  </Flex>
)

export const ConfigurationWidget = ({
  titleToken,
  showAction = false,
  actionDisabled = false,
  actionToken,
  handleActionClick,
  actionRoute,
  actionHref,
  actionHrefToken,
  actionOptions,
  children,
}) => {
  return (
    <StyledBox width="100%" padding={sizer(6)} marginBottom={sizer(6)}>
      <Flex minHeight={sizer(10)} justifyContent="space-between" alignItems="flex-start">
        <Text size="md" token={titleToken} bold />
        {showAction &&
          (actionOptions?.length > 0 ? (
            <Dropdown variant="compact" options={actionOptions} alignRight>
              <Text token={localizationKey("Actions")} color="action" bold />
              <OpenArrowIcon direction="down" color="action" />
            </Dropdown>
          ) : actionHref ? (
            <StyledLinkContainer>
              <a href={actionHref} target="_blank" rel="noopener noreferrer">
                {localized(actionHrefToken)}
              </a>
            </StyledLinkContainer>
          ) : (
            <Button
              variant="tertiary"
              labelToken={actionToken}
              disabled={actionDisabled}
              {...{
                ...(handleActionClick && { onClick: handleActionClick }),
                ...(actionRoute && { linkTo: actionRoute }),
              }}
            />
          ))}
      </Flex>
      <Box width="100%">{children}</Box>
    </StyledBox>
  )
}

ConfigurationWidget.propTypes = {
  titleToken: PropTypes.string.isRequired,
  showAction: PropTypes.bool,
  actionDisabled: PropTypes.bool,
  actionToken: PropTypes.string,
  handleActionClick: PropTypes.func,
  actionHref: PropTypes.string,
  actionHrefToken: PropTypes.string,
  actionOptions: PropTypes.array,
}
