import { fetchQuickConnectStatus } from "js/includes/common/client"
import { localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"

export const requestQuickConnectConfigs = () => async dispatch => {
  try {
    const response = await fetchQuickConnectStatus()

    dispatch({
      type: "QUICK_CONNECT_SET_CONFIGURATIONS",
      payload: response.content,
    })
  } catch (e) {
    reportErrorAndShowMessage(e, localizationKey("Failed to fetch QuickConnect status"))
  }
}

export const toggleQuickConnectStatus = () => dispatch => {
  dispatch({
    type: "QUICK_CONNECT_TOGGLE_STATUS",
  })
}
