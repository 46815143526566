import React, { PureComponent } from "react"
import { Modal } from "react-bootstrap"
import $ from "jquery"
import Portal from "js/includes/components/Portal"

export default class EditorPortalModal extends PureComponent {
  onModalShow = () => {
    $(document).off("focusin.modal")
  }

  componentDidMount() {
    const { show } = this.props
    show && this.onModalShow()
  }

  componentDidUpdate = prevProps => {
    const { prevShow } = prevProps
    const { show } = this.props

    if (!prevShow && show) {
      this.onModalShow()
    }
  }

  render() {
    const { show, children, bsSize, containerId, className, id } = this.props

    return (
      show && (
        <Portal {...{ containerId }}>
          <Modal.Dialog {...{ id, className, bsSize }}>{children}</Modal.Dialog>
        </Portal>
      )
    )
  }
}
