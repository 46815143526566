import { isEmpty } from "ramda"
import { showSuccessMessage, fetchJson, localized, isFeatureEnabled } from "js/includes/common/utils"

import { requestQuickBooksIntegrationSettings } from "./quickBooks"

const ninjaPSAConfigEndpoint = "/divisionconfig/ninja_psa"

export const requestNinjaPSAConfigs = () => async dispatch => {
  if (!isFeatureEnabled("ninja_psa")) return

  const response = await fetchJson(ninjaPSAConfigEndpoint)

  if (!isEmpty(response)) {
    dispatch({
      type: "NINJA_PSA_SET_CONFIGURATIONS",
      payload: response,
    })

    if (response.enabled) {
      dispatch(requestQuickBooksIntegrationSettings())
    }
  }
}

export const updateNinjaPSAConfigs = (content, method = "PUT") => async dispatch => {
  if (!isFeatureEnabled("ninja_psa")) return

  await fetchJson(ninjaPSAConfigEndpoint, {
    options: {
      method,
      body: JSON.stringify(content),
    },
  })
  showSuccessMessage(localized("Saving..."))
  dispatch(requestNinjaPSAConfigs())
}

export const setNinjaPSADataTablesData = tablesData => dispatch => {
  dispatch({
    type: "NINJA_PSA_SET_DATA_TABLES_DATA",
    payload: tablesData,
  })
}

export const resetNinjaPSADataTablesData = section => dispatch => {
  dispatch({
    type: "NINJA_PSA_RESET_DATA_TABLES_DATA",
    payload: section,
  })
}
