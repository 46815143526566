import { useRef } from "react"

export const useLongPress = (onClick, handleLongPress, handleLongPressEnd) => {
  const isLongPress = useRef()
  const timerRef = useRef()

  const handleOnClick = () => {
    if (isLongPress.current) return
    onClick?.()
  }

  const onLongPress = () => {
    isLongPress.current = false
    timerRef.current = setTimeout(() => {
      isLongPress.current = true
      handleLongPress?.()
    }, 500)
  }

  const onLongPressEnd = () => {
    clearTimeout(timerRef.current)
    handleLongPressEnd?.()
  }

  return {
    onClick: handleOnClick,
    onLongPress,
    onLongPressEnd,
  }
}
