import { compose } from "ramda"
import qs from "qs"
import { fetchJson, ninjaReportError } from "js/includes/common/utils"
import type { Product } from "js/state/types"
import { set } from "js/state/actions/common/readOnly"

const setProducts = (products: Product) => ({
  type: "SET_PRODUCTS",
  products,
})

const setIvantiProducts = products => ({
  type: "SET_IVANTI_PRODUCTS",
  products,
})

export const requestSetProducts = () => async dispatch => {
  try {
    const [products, ivantiProducts] = await Promise.all([
      fetchJson("/software/products/WINDOWS"),
      fetchJson("/software/products/OSX"),
    ])
    compose(dispatch, setProducts)(products)
    compose(dispatch, setIvantiProducts)(ivantiProducts)
  } catch (error) {
    ninjaReportError(error)
  }
}

export const requestDownloadableProducts = codes => async dispatch => {
  const _qs = codes ? qs.stringify({ codes: codes.join(",") }) : ""
  try {
    const downloadableProducts = await fetchJson(`/api/v2/components?${_qs}`)
    dispatch(set("downloadableProducts")(downloadableProducts))
  } catch (error) {
    ninjaReportError(error)
  }
}
