import { NinjaResponseError } from "js/includes/common/types"
import { isFeatureEnabled, localizationKey, reportErrorAndShowMessage, user } from "js/includes/common/utils"

const setConnection = payload => ({
  type: "SET_SERVICE_NOW_CONNECTION",
  payload,
})

const resetConnection = () => ({ type: "RESET_SERVICE_NOW_CONNECTION" })

export const getServiceNowConnection = () => async (dispatch, getState) => {
  try {
    if (!user("canConfigurePsa") || !isFeatureEnabled("service_now")) return

    const response = await window.psaConnect.fetch()

    if (response.resultCode === "SUCCESS") {
      const {
        basicAuthCredentials,
        enabled,
        instanceName,
        authType,
        oauthConfiguration,
        defaultTicketTemplateId,
        psaType,
        integrationTypeActivated,
      } = response.content || {}

      dispatch(
        psaType === "SERVICE_NOW"
          ? setConnection({
              basicAuthCredentials,
              oauthConfiguration,
              enabled,
              instanceName,
              authType,
              defaultTicketTemplateId,
              integrationTypeActivated,
            })
          : resetConnection(),
      )
    } else {
      throw new NinjaResponseError(response)
    }
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error fetching ServiceNow connection"))
  }
}
